import { SpSvg } from '~/autogen/SpSvg'
import { CheckboxTypes } from '~/constants/checkboxTypes'

export interface CheckboxTypeStatus {
	default: CheckboxSetup
	disabled: CheckboxSetup
}

export interface CheckboxSetup {
	classes: string
	icon?: {
		id: SpSvg
		fill: string
	}
}

export const buttonColorsConfig: { [k in CheckboxTypes]: CheckboxTypeStatus } = {
	[CheckboxTypes.REGULAR]: {
		default: {
			classes: `odd:bg-white even:bg-warm-grey-50 hover:bg-warm-grey-100`,
		},
		disabled: { classes: `bg-warm-grey-100` },
	},
	[CheckboxTypes.WARNING]: {
		default: {
			classes: `odd:bg-warning-50 even:bg-warning-100 hover:bg-warning-200`,
			icon: {
				id: SpSvg.BasicAlertCircle,
				fill: 'fill-warning-500',
			},
		},
		disabled: {
			classes: `bg-warm-grey-800`,
			icon: {
				id: SpSvg.BasicAlertCircle,
				fill: 'fill-warning-500',
			},
		},
	},
}

export const getCheckboxColorConfig = (key?: CheckboxTypes) =>
	buttonColorsConfig[key || CheckboxTypes.REGULAR] as CheckboxTypeStatus
